import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import api from '../../services/api'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/pt-br'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
dayjs.extend(customParseFormat)

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const ReceitaCategoria = ({ data_inicial, data_final, initBuscarItens, onBuscarItensEnd }) => {
    const [loading, setLoading] = useState(false)
    const toast = useRef(null)
    const [data, setData] = useState([])
    const coresLegais = ['#17A2B8', '#28A745', '#FFC107', '#F56954', '#007BFF', '#6C757D', '#6610f2', '#DC3545', '#FF5733', '#FFC300']

    const buscarItens = async () => {
        try {
            setLoading(true)
            const response = await api.get('/api/receitas/categoria?data_inicio=' + dayjs(data_inicial).format('DD/MM/YYYY') + '&data_fim=' + dayjs(data_final).format('DD/MM/YYYY'))
            setData(response.data)
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: e.response?.data?.message || 'Erro ao realizar operação', life: 3000 });
        } finally {
            setLoading(false)
            onBuscarItensEnd()
        }
    }

    useEffect(() => {
        if (initBuscarItens) {
            buscarItens()
        }
    }, [initBuscarItens])

    // Função para processar os dados e preparar para o gráfico
    const processarDadosGrafico = () => {
        if (!data || data.length === 0) return { labels: [], datasets: [] }

        // Extrair todos os meses (labels)
        const labels = data.map(item => item.data)

        // Extrair todas as categorias únicas
        const todasCategorias = new Set()
        data.forEach(item => {
            Object.keys(item.categorias).forEach(categoria => {
                todasCategorias.add(categoria)
            })
        })
        const categorias = Array.from(todasCategorias)

        // Criar datasets para cada categoria
        const datasets = categorias.map((categoria, index) => {
            // Para cada categoria, mapear os valores para cada mês
            const valores = data.map(item => {
                // Se a categoria existe para este mês, retornar o valor, senão retornar 0
                return item.categorias[categoria] || 0
            })

            return {
                label: categoria,
                data: valores,
                fill: false,
                backgroundColor: coresLegais[index % coresLegais.length],
                borderColor: coresLegais[index % coresLegais.length],
                tension: 0.3
            }
        })

        return { labels, datasets }
    }

    return (
        <div>
            <Toast ref={toast} position="top-center" />

            {loading && (
                <div className='flex justify-center items-center'>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                </div>
            )}

            {!loading && data.length > 0 && (
                <div className="chart-container">
                    <Line
                        options={{
                            scales: {
                                y: {
                                    beginAtZero: true
                                }
                            },
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            let label = context.dataset.label || '';
                                            if (label) {
                                                label += ': ';
                                            }
                                            if (context.parsed.y !== null) {
                                                label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(context.parsed.y);
                                            }
                                            return label;
                                        }
                                    }
                                },
                                datalabels: {
                                    display: true,
                                    align: 'top',
                                    color: '#000',
                                    font: {
                                        weight: 'bold'
                                    },
                                    formatter: function (value, context) {
                                        return value ? Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) : 'R$ 0,00'
                                    }
                                }
                            }
                        }}
                        data={processarDadosGrafico()}
                    />
                </div>
            )}
        </div>
    )
}

export default ReceitaCategoria 